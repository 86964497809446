import React from "react";
import AnnotationWindow from "../pages/inspections/AnnotationWindow";
import Loader from "./Loader";
import Next from "../assets/img/icons/next.png";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CustomModal = ({
  isOpen,
  onClose,
  imageData,
  handleNextImage,
  handlePrevImage,
  index,
  annotateData,
  loader,
  setLoader,
  rowData,
}) => {
  const navigate = useNavigate();
  if (!isOpen) return null; // Don't render if the modal is not open

  const handleNavigate = () => {
    navigate(
      window.location.pathname +
        "/" +
        rowData.name +
        "/" +
        rowData.structure_id +
        "/" +
        imageData.id
    );
  };

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal-content">
        <button onClick={onClose} className="custom-modal-close-btn">
          &times;
        </button>
        <div className="custom-modal-body">
          {loader && <Loader />}
          <AnnotationWindow
            selectedImageDetails={imageData}
            annotateData={annotateData}
            index={index}
            setAnnotationWindowLoad={setLoader}
          />
          <Tooltip title="See Details" arrow placement="top">
            <button
              onClick={handleNavigate}
              className="custom-modal-navigate-btn"
            >
              <img src={Next} alt="navigate" style={{ width: "25px" }} />
            </button>
          </Tooltip>
        </div>
        {index !== 0 && (
          <button onClick={handlePrevImage} className="custom-modal-prev-btn">
            &larr;
          </button>
        )}
        {index !== rowData.defects.defect_list.length - 1 && (
          <button onClick={handleNextImage} className="custom-modal-next-btn">
            &rarr;
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
