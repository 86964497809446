import React, { Component, useContext, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Button,
  Chip,
  Collapse,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import {
  StatusConstants,
  ThreeDotsIcon,
  getSeverityTag,
  getStatusStyles,
} from "../../helpers/Constants";
import { useState, useEffect } from "react";
import { chartData1, emptyChartData } from "../../helpers/Utils";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import StatusSelectionPopup from "./StatusSelectionPopup";
import Loader from "../../helpers/Loader";
import AuthContext from "../auth/AuthContext";
import Reset from "../../assets/img/icons/reset.png";
import KeyboardArrowDownIcon from "../../assets/img/icons/chevronDownBlack.png";
import KeyboardArrowUpIcon from "../../assets/img/icons/chevronUpBlack.png";
import CustomModal from "../../helpers/CustomModal";

const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

const columns = [
  {
    id: "download_photos",
    label: "D",
    minWidth: 100,
    align: "right",
  },
  { id: "name", label: "Name", minWidth: 150, align: "right" },
  { id: "status", label: "Status", minWidth: 150, align: "right" },
  {
    id: "image_count",
    label: "Number of Images",
    minWidth: 150,
    align: "right",
  },
  {
    id: "defects",
    label: "Defects",
    minWidth: 200,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const InspectionsViewTable = ({
  searchTerm,
  selectedStatus,
  selectedSeverity,
  selectedComponent,
  id,
  checkedRows,
  updateCheckedRows,
  headingCheckbox,
  handleHeadingCheckboxChange,
  updateTotalRowsCount,
  statusChange,
  handleStatusChange,
  navigate,
  apiError,
  setApiError,
  tableLoad,
  setTableLoad,
  setComponents,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [statusPopup, setStatusPopup] = useState(false);
  const [rowId, setRowId] = useState("");

  const [openRows, setOpenRows] = useState({}); // Track the open/collapsed rows

  const [openModal, setOpenModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null); // Track current image
  const [imageData, setImageData] = useState(null); // Store image data from API
  const [structureId, setStructureId] = useState("");
  const [annotateData, setAnnotateData] = useState("");
  const [loader, setLoader] = useState(false);
  const [rowData, setRowData] = useState({});

  const handleOpenModal = async (index, imageId, structureId, rowData) => {
    setRowData(rowData);
    setLoader(true);
    setStructureId(structureId);
    setCurrentImageIndex(index);
    setAnnotateData(rowData.defects.defect_list[index].data);
    const data = await window.apiHelper.getPhotoAnnotationDetails(imageId); // Call API to fetch image
    setImageData(data); // Store the fetched image data
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setImageData(null);
    setStructureId("");
    setAnnotateData("");
  };

  const handleNextImage = () => {
    setLoader(true);

    const nextIndex =
      (currentImageIndex + 1) % rowData.defects.defect_list.length;
    setCurrentImageIndex(nextIndex);
    setAnnotateData(rowData.defects.defect_list[nextIndex].data);
    const nextImageId = rowData.defects.defect_list[nextIndex].image_id;
    window.apiHelper.getPhotoAnnotationDetails(nextImageId).then((data) => {
      setImageData(data);
    });
  };

  const handlePrevImage = () => {
    setLoader(true);

    const prevIndex =
      (currentImageIndex - 1 + rowData.defects.defect_list.length) %
      rowData.defects.defect_list.length;
    setCurrentImageIndex(prevIndex);
    setAnnotateData(rowData.defects.defect_list[prevIndex].data);
    const prevImageId = rowData.defects.defect_list[prevIndex].image_id;

    window.apiHelper
      .getPhotoAnnotationDetails(prevImageId)
      .then((data) => setImageData(data));
  };

  const handleRowClick = (rowId) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId], // Toggle the open state for the row
    }));
  };

  const authContext = useContext(AuthContext);

  const fetchData = async () => {
    try {
      setTableLoad(true);
      const workordersList = await window.apiHelper.getInspectionTableData(
        page + 1,
        rowsPerPage,
        searchTerm,
        selectedStatus,
        selectedSeverity,
        selectedComponent,
        id
      );

      setComponents(workordersList.all_components);

      setData(workordersList);
      updateTotalRowsCount(workordersList?.count);
      const checkedRows = {};
      workordersList?.results?.forEach((row) => {
        checkedRows[row.structure_id] = headingCheckbox;
      });
      setTableLoad(false);
      setApiError(false);
      headingCheckbox && updateCheckedRows(checkedRows);
    } catch (error) {
      console.log(error);
      setTableLoad(false);
      setApiError(true);
    }
  };

  useEffect(() => {
    fetchData();
    handleStatusChange(false);
  }, [
    rowsPerPage,
    page,
    searchTerm,
    selectedStatus,
    selectedSeverity,
    selectedComponent,
    statusChange,
  ]);

  useEffect(() => {
    const checkedRows = {};

    data?.results?.forEach((row) => {
      checkedRows[row.structure_id] = headingCheckbox;
    });
    headingCheckbox ? updateCheckedRows(checkedRows) : updateCheckedRows({});
  }, [headingCheckbox]);

  ChartJS.register(ArcElement, Legend);
  let chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCheckboxChange = (event, structureId) => {
    const newCheckedRows = {
      ...checkedRows,
      [structureId]: event.target.checked,
    };

    if (!event.target.checked) {
      delete newCheckedRows[structureId];
    }

    !headingCheckbox && updateCheckedRows(newCheckedRows);
  };

  const handleStructureClick = async (row) => {
    try {
      window.appComponent.setState({
        loaderVisible: true,
      });
      const images = await window.apiHelper.getImagesList(
        row.request_id,
        row.structure_id
      );

      const imageDetails = await window.apiHelper.getPhotoAnnotationDetails(
        images[0].id
      );

      navigate(
        window.location.pathname +
          "/" +
          row.name +
          "/" +
          row.structure_id +
          "/" +
          imageDetails.id
      );
    } catch (e) {
      console.log(e);
      window.appComponent.setState({
        loaderVisible: false,
      });
    }
  };

  const handleStatusPopup = (row) => {
    setRowId(row.id);
    setStatusPopup(!statusPopup);
  };

  const handleRetry = () => {
    fetchData();
  };

  return (
    <Paper
      sx={{
        width: "100%",
        position: "relative",
        height: "calc(100vh - 310px)",
      }}
    >
      <TableContainer sx={{ height: "90.5%" }}>
        {apiError ? (
          tableLoad ? (
            <Loader />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <h1>Something went wrong</h1>
              <button className="retry-button" onClick={handleRetry}>
                <img src={Reset} alt="reset" className="button-retry" />
              </button>
            </div>
          )
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: 50,
                    background: "#EEF1F8",
                  }}
                />
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      background: "#EEF1F8",
                      fontFamily: "inherit",
                      fontWeight: 600,
                      fontSize: "14.5px",
                      textAlign: "center",
                    }}
                  >
                    {column.id === "download_photos" ? (
                      <Tooltip title="Select All" arrow placement="top">
                        <Checkbox
                          checked={headingCheckbox}
                          onChange={handleHeadingCheckboxChange}
                          color="primary"
                          inputProps={{ "aria-label": "checkbox" }}
                        />
                      </Tooltip>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableLoad ? (
              <Loader />
            ) : (
              <TableBody>
                {data.length !== 0 &&
                  data.results.map((row, rowIndex) => {
                    const isRowOpen = openRows[row.id] || false;
                    return (
                      <React.Fragment key={row.name}>
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleRowClick(row.id)}
                            >
                              {isRowOpen ? (
                                <img
                                  src={KeyboardArrowUpIcon}
                                  alt="up"
                                  style={{ width: "20px" }}
                                />
                              ) : (
                                <img
                                  src={KeyboardArrowDownIcon}
                                  alt="down"
                                  style={{ width: "20px" }}
                                />
                              )}
                            </IconButton>
                          </TableCell>
                          {columns.map((column, columnIndex) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  maxWidth: column.minWidth,
                                  color: "inherit",
                                  fontFamily: "inherit",
                                  fontWeight: 500,
                                  textAlign: "center",
                                }}
                              >
                                {column.id === "name" ? (
                                  <button
                                    style={{
                                      borderRadius: "10px",
                                      padding: "5px 8px",
                                      backgroundColor: "white",
                                      color: "rgba(39, 197, 155, 1)",
                                      border: "1px solid #acacac",
                                      boxShadow:
                                        "0px 0px 7px 0px rgba(0, 0, 0, 0.15)",
                                    }}
                                    onClick={() => handleStructureClick(row)}
                                  >
                                    {value}
                                  </button>
                                ) : column.id === "status" ? (
                                  <div>
                                    <Button
                                      style={{
                                        borderRadius: "30px",
                                        padding: "0px",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                        position: "relative",
                                      }}
                                    >
                                      <Chip
                                        label={
                                          <div>
                                            {StatusConstants[value]}
                                            {authContext.permissions.includes(
                                              "can_change_structure_status"
                                            ) && (
                                              <ThreeDotsIcon
                                                style={{
                                                  color: `${getStatusStyles(
                                                    value
                                                  )}`,
                                                  transform: "rotate(90deg)",
                                                  marginLeft: "20px",
                                                }}
                                                fontSize="small"
                                                onClick={() =>
                                                  handleStatusPopup(row)
                                                }
                                              />
                                            )}
                                          </div>
                                        }
                                        style={getStatusStyles(value)}
                                      />
                                      <div
                                        className={`${
                                          value === 2
                                            ? "popup-status-select-small"
                                            : "popup-status-select-large"
                                        }`}
                                      >
                                        {statusPopup && rowId === row.id && (
                                          <StatusSelectionPopup
                                            value={value}
                                            setStatusPopup={setStatusPopup}
                                            rowId={rowId}
                                            statusChange={statusChange}
                                            handleStatusChange={
                                              handleStatusChange
                                            }
                                            setTableLoad={setTableLoad}
                                          />
                                        )}
                                      </div>
                                    </Button>
                                  </div>
                                ) : column.id === "download_photos" ? (
                                  <Checkbox
                                    checked={
                                      checkedRows[row.structure_id] || false
                                    }
                                    onChange={(event) =>
                                      handleCheckboxChange(
                                        event,
                                        row.structure_id
                                      )
                                    }
                                    color="primary"
                                    inputProps={{ "aria-label": "checkbox" }}
                                  />
                                ) : column.id === "defects" ? (
                                  <div className="structure-stats-tableView">
                                    <Doughnut
                                      data={
                                        value.high_level_defects === 0 &&
                                        value.medium_level_defects === 0 &&
                                        value.low_level_defects === 0
                                          ? emptyChartData([1])
                                          : chartData1([
                                              value.high_level_defects,
                                              value.medium_level_defects,
                                              value.low_level_defects,
                                            ])
                                      }
                                      options={chartOptions}
                                    />
                                    <div className="structure-stats-text">
                                      <span className="issues-high">
                                        High: {value.high_level_defects}
                                      </span>
                                      <span className="issues-medium">
                                        Medium: {value.medium_level_defects}
                                      </span>
                                      <span className="issues-low">
                                        Low: {value.low_level_defects}
                                      </span>
                                    </div>
                                  </div>
                                ) : column.id === "image_count" ? (
                                  <div style={{ fontSize: "15px" }}>
                                    {value}
                                  </div>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={columns.length + 1}
                          >
                            <Collapse
                              in={isRowOpen}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <div>
                                  {row.defects.defect_list?.map(
                                    (defect, index) => (
                                      <>
                                        <div
                                          key={defect.image_id}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {/* Severity Tag */}
                                          {getSeverityTag(defect.severity)}

                                          {/* Feature, Defect, and Description */}
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleOpenModal(
                                                index,
                                                defect.image_id,
                                                row.structure_id,
                                                row
                                              )
                                            }
                                          >
                                            <strong>{defect.feature}</strong>:{" "}
                                            {defect.defect.join(", ")}
                                          </span>
                                        </div>
                                        {/* Divider Line after each defect */}
                                        {index <
                                          row.defects.defect_list.length -
                                            1 && (
                                          <Divider
                                            style={{
                                              marginBottom: "10px",
                                              borderColor: "#8a8a8a",
                                            }}
                                          />
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                        {/* Modal for Image Popup */}
                        <CustomModal
                          isOpen={openModal}
                          onClose={handleCloseModal}
                          imageData={imageData}
                          handleNextImage={handleNextImage}
                          handlePrevImage={handlePrevImage}
                          index={currentImageIndex}
                          annotateData={annotateData}
                          loader={loader}
                          setLoader={setLoader}
                          rowData={rowData}
                        />
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        )}
      </TableContainer>
      <div
        className={`table-pagination-container ${apiError ? "disabled" : ""}`}
      >
        <div className="table-pagination-heading">
          {headingCheckbox ? (
            <h2>{`${data.count} rows selected`}</h2>
          ) : (
            Object.keys(checkedRows).length > 0 && (
              <h2>{`${Object.keys(checkedRows).length} rows selected`}</h2>
            )
          )}
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.count ? data.count : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              margin: 0,
            },
            "& .MuiTablePagination-displayedRows": {
              margin: 0,
            },
          }}
        />
      </div>
    </Paper>
  );
};

export default withNavigate(InspectionsViewTable);
